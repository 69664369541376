<template>
  <footer class="footer">
    <div class="container">
      <a class="footer__logo" target="_blank">
        <img
          v-if="$i18n.locale === 'ru'"
          src="@/assets/img/icons/logo-footer.svg"
          alt="Logo Magnum"
          width="223"
          height="66"
        />
        <img
          v-else
          src="@/assets/img/icons/logo-footer-uz.svg"
          alt="Logo Magnum"
          width="723"
          height="66"
        />
      </a>
      <div class="footer__menu">
        <div class="footer__left">
          <div
            v-for="(accordion, index) in accordions"
            :key="index"
            class="footer-item"
          >
            <div
              class="footer-item__title"
              @click="toogleAccordions(accordion)"
              :class="{ 'is-active': accordion.isOpen }"
            >
              {{ $t(accordion.title) }}
            </div>
            <div
              v-for="(item, index) in accordion.list"
              :key="index"
              class="footer-item__list"
              :class="{ 'is-active': accordion.isOpen }"
            >
              <locale-router-link
                v-if="item.router"
                :to="item.router"
                class="footer-item__link"
              >
                {{ $t(item.label) }}
              </locale-router-link>
              <a
                v-else-if="$i18n.locale === 'ru'"
                :href="item.link"
                target="_blank"
                class="footer-item__link"
              >
                {{ $t(item.label) }}
              </a>
              <a
                v-else
                :href="item.linkUz"
                target="_blank"
                class="footer-item__link"
              >
                {{ $t(item.label) }}
              </a>
            </div>
          </div>
        </div>

        <div class="footer-info">
          <div class="footer-info__support">{{ $t("support") }}</div>

          <div class="footer-info__phone">
            <a target="_blank" href="tel:998555000066"> +998 55 500-00-66 </a>
          </div>

          <div v-if="social" class="footer-info__social-links">
            <span>{{ $t("weAreInSocialNetworks") }}</span>

            <div class="footer-info__links">
              <a
                v-if="social.attributes.facebook_active"
                class="fb"
                :href="social.attributes.facebook_link"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.IG_active"
                class="insta"
                :href="social.attributes.IG"
                target="_blank"
              ></a
              ><a
                v-if="social.attributes.telegram_active"
                class="telegram"
                :href="social.attributes.telegram"
                target="_blank"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getSocialLink } from "@/api/links";
export default {
  name: "AppFooter",
  data() {
    return {
      social: null,
      accordions: [
        {
          id: 1,
          title: "aboutCompany",
          isOpen: false,
          list: [
            {
              router: "about",
              label: "magnumCompany",
            },
            {
              router: "news",
              label: "companyNews",
            },
            {
              link: "https://magnum.jobster.uz/",
              linkUz: "https://magnum.jobster.uz/uz",
              label: "сareerMagnum",
            },
          ],
        },
        {
          id: 2,
          title: "buyers",
          isOpen: false,
          list: [
            {
              router: "catalog",
              label: "catalog",
            },
            {
              router: "stocks",
              label: "stock",
            },
            {
              router: "magnumclub",
              label: "magnumclub",
            },
            {
              router: "cooking",
              label: "cookingWithMagnum",
            },
            {
              router: "contacts",
              label: "contacts",
            },
          ],
        },
      ],
      selectedAccordions: null,
    };
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
      console.log("social", social);
    });
  },
  methods: {
    toogleAccordions(item) {
      const foundFilter = this.accordions.find((f) => f.id === item.id);
      if (foundFilter) {
        foundFilter.isOpen = !foundFilter.isOpen;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
